<template>
    <div class="Home">
      <page-header/>

      <section class="uk-section uk-section-small uk-padding-remove-top">
        <div class="uk-container">
          <div class="uk-grid-medium uk-child-width-1-1" uk-grid>
            <div class="uk-text-center">
              <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
                <li>
                  <a href>Home</a>
                </li>
                <li>
                  <span>Quizs</span>
                </li>
              </ul>
              <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Cart</h1>-->
            </div>
            <div>
              <div>
                <div class="uk-grid-medium" uk-grid>
                  <div class="uk-width-1-1 uk-width-expand@m">

                    <div class="uk-hidden uk-grid uk-grid-small uk-margin-medium-bottom uk-margin-medium-top" uk-margin>

                      <div class="uk-inline">
                        <input v-model="sdate" class="uk-input" type="date">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                      </div>
                      <div class="uk-inline">
                        <input v-model="edate" class="uk-input" type="date">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: calendar"></span>
                      </div>
                      <div>
                        <button type="button" @click="searchDate" class="uk-button uk-button-default">조회하기</button>
                      </div>
                    </div>

                    <div class="uk-card uk-card-default uk-card-small tm-ignore-container">

                      <header class="uk-card-header">
                        <div class="uk-grid-small uk-flex-middle" uk-grid>
                          <div
                            class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small"
                          >
                              <span
                                style="margin-right:10px"
                                class="uk-flex uk-flex-middle"
                              >Sort by:</span>

                            <div uk-form-custom="target: > * > span:first-child">
                              <select v-model="sortselected" @change="onChangeSort()">
                                <option value="norm">Normal</option>
                                <option value="name">Name</option>
                                <option value="email">Email</option>
                                <option value="enabled">Enabled</option>
                              </select>
                              <button
                                class="uk-button uk-button-default"

                                tabindex="-1"
                              >

                                <span uk-icon="icon: chevron-down"></span>
                              </button>
                            </div>
                          </div>
                          <div
                            class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle"
                          >

                            <router-link class="uk-button uk-button-default uk-button-small uk-hidden@m"

                                         title="Quiz register" tag="a" :to="{name: 'studentregister'}"><span
                              class="uk-margin-xsmall-right"
                              uk-icon="icon: plus; ratio: .75;"
                            ></span>Create quiz
                            </router-link>

                            <div class="tm-change-view uk-margin-small-left">
                              <ul class="uk-subnav uk-iconnav js-change-view">
                                <li>
                                  <router-link class="uk-button uk-button-default uk-button-small"
                                               style="color: rgb(1, 176, 255); border-color: rgb(1, 176, 255)"
                                               title="Quiz register" tag="a" :to="{name: 'studentregister'}"><span
                                    class="uk-margin-xsmall-right"
                                    uk-icon="icon: plus; ratio: .95;"
                                  ></span>Create quiz
                                  </router-link>
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </header>
                      <div class="uk-card-body">
                        <table v-if="quizs" id="example"
                               class="uk-table uk-table-hover uk-table-striped uk-table-responsive"
                               style="width:100%">
                          <thead>
                          <tr>
                            <th>ID</th>
                            <th>Question</th>
                            <th>Difficulty</th>
                            <!--<th>Created at</th>-->
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody v-if="quizs.length > 0">

                          <tr v-for="item in quizs"
                              :item="item"
                              :key="item.id">

                            <td>{{item.id}}</td>
                            <td><span v-html="item.question"></span></td>
                            <td>{{item.level}}</td>

                            <!--<td>{{item.createdAt | moment}}</td>-->
                            <td><a @click="getQuizInfo(item.id)"
                                   class="uk-button uk-button-primary uk-button-small uk-text-center">Result</a>

                            </td>
                          </tr>

                          </tbody>

                        </table>
                        <div v-else class="uk-alert-primary " uk-alert>

                          <p class="uk-text-center">Empty.</p>
                        </div>
                      </div>
                      <div class="uk-card-footer uk-text-center">

                        <div class="uk-flex uk-flex-center">

                          <v-pagination v-model="currentPage"
                                        :page-count="pages"
                                        :classes="uikitClasses"
                                        :labels="Labels"
                                        @change="onChange"></v-pagination>

                        </div>

                      </div>


                    </div>
                  </div>
                  <div class="uk-width-1-1 tm-aside-column uk-width-1-3@m">
                    <div
                      class="uk-card uk-card-default uk-card-small tm-ignore-container"
                      uk-sticky="offset: 40; bottom: true; media: @m;"
                    >
                      <div class="uk-card-body">
                        <div v-if="quizinfomain!=null">


                          <table class="uk-table uk-table-divider uk-table-striped uk-text-center">
                            <thead>
                            <tr>
                              <th>LEVEL\난이도</th>
                              <th>상</th>
                              <th>중</th>
                              <th>하</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>Level 1</td>
                              <td>
                                <span v-if="quizinfomain[2].level1Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[2].level1Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[1].level1Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[1].level1Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[0].level1Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[0].level1Avarage.toFixed(2)}}</span>

                              </td>
                            </tr>

                            <tr>
                              <td>Level 2</td>
                              <td>
                                <span v-if="quizinfomain[2].level2Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[2].level2Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[1].level2Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[1].level2Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[0].level2Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[0].level2Avarage.toFixed(2)}}</span>

                              </td>
                            </tr>
                            <tr>
                              <td>Level 3</td>
                              <td>
                                <span v-if="quizinfomain[2].level3Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[2].level3Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[1].level3Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[1].level3Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[0].level3Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[0].level3Avarage.toFixed(2)}}</span>

                              </td>
                            </tr>

                            <tr>
                              <td>Level 4</td>
                              <td>
                                <span v-if="quizinfomain[2].level4Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[2].level4Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[1].level4Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[1].level4Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[0].level4Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[0].level4Avarage.toFixed(2)}}</span>

                              </td>
                            </tr>

                            <tr>
                              <td>Level 5</td>
                              <td>
                                <span v-if="quizinfomain[2].level5Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[2].level5Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[1].level5Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[1].level5Avarage.toFixed(2)}}</span>
                              </td>
                              <td>
                                <span v-if="quizinfomain[0].level5Avarage === 'NaN'">NaN</span>
                                <span v-else>{{quizinfomain[0].level5Avarage.toFixed(2)}}</span>


                              </td>
                            </tr>

                            </tbody>
                          </table>

                        </div>
                        <div v-else class="uk-text-center">결과없음</div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div id="modal-result" uk-modal>
        <div v-if="quizinfo!=null" class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default"  uk-close></button>
          <h3 class="uk-modal-title">ID:{{quizinfo.quizId}}
            LEVEL: <span v-if="quizinfo.difficuliy == 1">LOW</span>
            <span v-else-if="quizinfo.difficuliy == 2">MID</span>
            <span v-else-if="quizinfo.difficuliy == 3">HIGH</span>
          </h3>

          <div>


            <table class="uk-table uk-table-divider uk-table-striped uk-text-center">
              <thead>
              <tr>
                <th>.</th>
                <th>Correct answer rate</th>
                <th>Correct answer</th>
                <th>Total Display</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Level 1</td>
                <td>{{quizinfo.leve11FlagYCount/(quizinfo.level1TotalDisplay-1)}}</td>
                <td>{{quizinfo.leve11FlagYCount}}</td>
                <td> {{quizinfo.level1TotalDisplay-1}}</td>
              </tr>

              <tr>
                <td>Level 2</td>

                <td>{{quizinfo.leve12FlagYCount/(quizinfo.level2TotalDisplay-1)}}</td>
                <td>{{quizinfo.leve12FlagYCount}}</td>
                <td> {{quizinfo.level2TotalDisplay-1}}</td>
              </tr>

              <tr>
                <td>Level 3</td>
                <td>{{quizinfo.leve13FlagYCount/(quizinfo.level3TotalDisplay-1)}}</td>
                <td>{{quizinfo.leve13FlagYCount}}</td>
                <td> {{quizinfo.level3TotalDisplay-1}}</td>
              </tr>

              <tr>
                <td>Level 4</td>

                <td>{{quizinfo.leve14FlagYCount/(quizinfo.level4TotalDisplay-1)}}</td>
                <td>{{quizinfo.leve14FlagYCount}}</td>
                <td> {{quizinfo.level4TotalDisplay-1}}</td>
              </tr>

              <tr>
                <td>Level 5</td>
                <td>{{quizinfo.leve15FlagYCount/(quizinfo.level5TotalDisplay-1)}}</td>
                <td>{{quizinfo.leve15FlagYCount}}</td>
                <td> {{quizinfo.level5TotalDisplay-1}}</td>
              </tr>

              </tbody>
            </table>

          </div>


        </div>
      </div>

      <page-footer/>

    </div>

</template>


<script scoped>
  import QuizService from '@/services/QuizService'
  import moment from 'moment'
  import vPagination from '@/components/globals/vue-plain-pagination'
  import UIkit from 'uikit'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
	const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  export default {
    name: 'Organiz',
    components: {
      vPagination,
      PageHeader,
      PageFooter

    },
    data() {
      return {
        quizinfo: null,
        quizinfomain: null,
        sdate: null,
        edate: null,
        error: null,
        sortselected: 'norm',
        quizs: null,
        currentPage: 1,
        pages: 0,
        uikitClasses: {
          ul: 'uk-pagination',
          li: '',
          liActive: 'uk-active',
          liDisable: 'uk-disabled',
          button: 'page-link'
        },
        Labels: {
          first: 'First',
          prev: '<span uk-pagination-previous></span>',
          next: '<span uk-pagination-next></span>',
          last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
        }

      }
    },

    filters: {
      moment: function (date) {
        return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      }
    },
    created() {
    },
    async mounted() {
      this.loaddata(this.currentPage)
      this.getQuizInfoMain()
    },
    methods: {
      onChangeSort() {
        this.loaddata(this.currentPage)
      },
      searchDate() {
        this.loaddata(this.currentPage)
      },

      async getQuizInfo(quizid) {
        try {

          this.quizinfo = null

          const response = await QuizService.getQuizInfo({
            quizid: quizid
          })

          console.log(response.data)
          this.quizinfo = response.data
          if (this.quizinfo) {
            UIkit.modal('#modal-result', null).show();
          }
        } catch (error) {
          console.log(error)
          this.error = error.response.data.error
        }
      },

      async getQuizInfoMain() {
        try {
          this.quizinfomain = null
          const response = await QuizService.getQuizInfoMain({})
          console.log("fffffffffffffffff")
          console.log(response.data)
          this.quizinfomain = response.data

        } catch (error) {
          console.log(error)
          this.error = error.response.data.error
        }
      },

      async loaddata(page) {
        try {

          if (page > 0) {
            page = page - 1
          }

          const response = await QuizService.getQuizsByPage({

            sdate: this.sdate,
            edate: this.edate,
            page: page
            // sort: this.sortselected

          });

          console.log(response)

          if (response.status == 200) {
            this.quizs = response.data.content;
            // this.currentPage = response.data.page;
            this.pages = response.data.totalPages;


            if (!this.quizs.length) {
              this.isLoading = false
              return
            }
          }
        } catch (error) {
          console.log(error.response)
          this.error = error.response.data.message
          this.scrollToTop()
        }

      },
      scrollToTop() {
        window.scrollTo(0, 0)
      }
      ,
      onChange: function () {
        // eslint-disable-next-line no-console
        console.log(`"currentPage" has been changed`)
        this.loaddata(this.currentPage)
      }

    },
    computed: {}

  }
</script>

<style scoped>
  .uk-pagination > li > a {
    font-size: 18px !important;
  }
  .uk-card-body {
    padding: 20px 20px;
  }
</style>
